<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle
      title="
      Applied Application"
      slot="title"
      link="/helpContent/appliedApplications"
    />

    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-6"><h2>Candidate Details</h2></div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-2 formDiv">
          <label class="formLable">Full Name</label>
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputText v-model="form.fullName" rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-2 formDiv">
          <label class="formLable">Email</label>
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputText v-model="form.email" rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-2 formDiv">
          <label class="formLable">Phone</label>
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputTel v-model="form.phone" rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-2 formDiv">
          <label class="formLable">DOB</label>
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputDate v-model="form.DOB" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-2 formDiv">
          <label class="formLable">Gender</label>
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <FormSelect
            v-model="form.gender"
            :items="genderList"
            rules="required"
            :disabled="readOnly"
            item-name="name"
            item-value="id"
          />
        </div>
      </FormRow>

      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-2 formDiv">
          <label class="formLable">Years of Experience</label>
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputIntegerNumber
            v-model="form.experience"
            rules="required"
            :readonly="readOnly"
          />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-2 formDiv">
          <label class="formLable">Qualification</label>
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <FormSelect
            v-model="form.qualification"
            :items="qualificationList"
            rules="required"
            :disabled="readOnly"
            item-name="name"
            item-value="name"
          />
        </div>
        <!-- <div class="col-sm-3">
          <inputText
            v-model="form.degree"
            name="Degree"
            rules="required"
            :readonly="readOnly"
          />
        </div> -->
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-2 formDiv">
          <label class="formLable">Brief Profile</label>
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-6">
          <InputTextArea
            v-model="form.briefProfile"
            rules="required"
            :readonly="readOnly"
          />
        </div>
      </FormRow>

      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-2 formDiv">
          <label class="formLable">Position Applying for</label>
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <!-- <FormSelect  v-model="form.position"   :items="positionList" rules="required" :disabled="readOnly" 
            item-name="name"
            item-value="id" /> -->

          <inputText v-model="form.position" rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-2 formDiv">
          <label class="formLable">Resume/CV</label>
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <div id="preview">
            <!-- <img v-if="file" :src="file" class="displayImageFile" /> -->
            <img v-if="file" :src="require(`Assets/images/PDF.png`)" class="displayPDF" @click="resumeView()"/>
          </div>
          <InputFile
            :rules="{ uploadRequired: false, docCheck: 'PDF,pdf', checkFileRegex: true }"
            :disabled="readOnly"
            :value="form.resume"
            name="Resume Upload"
            label="Resume Upload"
            @onChange="fileUpload"
          />
        </div>
      </FormRow>
      <hr>
      <FormRow></FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-6"><h2>HR View</h2></div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-2 formDiv">
          <label class="formLable">Questions for Screening</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-6">
          <InputTextArea :maxlength="2000" v-model="form.questionsforScreening"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow> 
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-2 formDiv">
          <label class="formLable">Status</label>
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-2">
          <FormSelect
            v-model="form.status"
            :items="statusList"
            rules="required"
            item-name="Name"
            item-value="Id"
          />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-2 formDiv">
          <label class="formLable">Comments</label>
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-6">
          <InputTextArea v-model="form.comment" rules="required" />
        </div>
      </FormRow>

    </ValidationObserver>
    <br />
    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton
            type="info"
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Back</FormButton
          >
          <FormButton type="success" @click="sendData()">Save</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        <FormButton
          @click="
            () => {
              $router.go(-1);
            }
          "
          >Ok</FormButton
        >
      </div>
    </Popup>
    <Popup
      title="Information"
      type="info"
      :value="infoMessage.isVisible"
      @close="() => (infoMessage.isVisible = false)"
      :closable="true"
    >
      {{ infoMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import { addUpdateApplication, getApplicationList, resumeUploads ,getApplicationStatus} from "../api";
import InputDate from "Components/form/InputDate";
import InputTel from "Components/form/InputTel";
import { ValidationObserver } from "vee-validate";
import validateSelectedRows from "Mixins/validateSelectedRows";
import config from "Config/base";
export default {
  name: "add",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    FormWrapper,
    PageTitle,
    ValidationObserver,
    InputDate,
    InputTel,
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      infoMessage: {
        isVisible: false,
      },
      filtering: {
        year: new Date().getFullYear(),
      },
      sorting: {
        key: "id",
        order: "asc",
      },
      pagination: {
        perPage: 10,
        page: 1,
        total: 0,
      },
      form: {
        id: 0,
        fullName: "",
        email: "",
        phone: "",
        DOB: "",
        gender: "",
        experience: "",
        qualification: "",
        degree: "",
        briefProfile: "",
        position: "",
        resumePath: "",
        status:null,
        comment: "",
        questionsforScreening:'',	
        company: "NetEdge",
        positionId:0,
        status:0
      },
      positionList: [],
      qualificationList: [
        { name: "Doctorate", id: 1 },
        { name: "Post Graduate", id: 2 },
        { name: "Graduate", id: 3 },
        { name: "Other", id: 4 },
      ],
      genderList: [
        { name: "Male", id: "M" },
        { name: "Female", id: "F" },
      ],
      statusList: [],
      readOnly: false,
    };
  },
  // computed: {
  //   checkedNames () {
  //      return this.names.filter(item => item.checked).map(name => name.name)
  //   }
  // },
  created() {
    this.getStatus();
    if (this.$route.params.id) {
      this.showLoader();
      this.form.id = atob(this.$route.params.id.split("&")[0]);
      this.form.positionId=atob(this.$route.params.id.split("&")[1]);
      this.form.status=atob(this.$route.params.id.split("&")[2]);
      this.form.termsAndConditionsAcceptance = true;
      this.getData();
    }
    if (this.$route.name === "applicationView") {
      this.readOnly = true;
    }

  },
  methods: {
    resumeView(){
window.open(
  config["imageUrl"]+'wpConnector/' + this.form.resumePath,
  '_blank' )
    },
    getStatus(){
      getApplicationStatus().then((res)=>{
this.statusList=res.data;
      })
    },
    fileUpload(file) {
      if (file) {
        const formData = new FormData();

        formData.append("file", file);
        formData.append("folderName", "LFCFiles");
        resumeUploads(formData).then((res) => {
          if (res.resumePath) {
            this.form.resumePath = res.resumePath;
            this.createFile(file);
          }
        });
      }
    },
    createFile(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.file = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    displayFile() {
      this.file = "";
      if (this.form.resumePath) {
        this.file = config["imageUrl"] + this.form.resumePath;
      }
    },
    getData() {
      const { key, order } = this.sorting;
      const { perPage, page } = this.pagination;

      const filters = {};
      filters.id = this.form.id;
      filters.positionId = this.form.positionId;
      filters.status = this.form.status;
      const data = {
        filterjson: {
          filter: [filters],
          sort: [{ key, order }],
          paging: [
            {
              startIndex: page,
              pagesize: perPage,
            },
          ],
        },
      };
      getApplicationList(data).then((res) => {
        this.form.fullName = res.data[0].Name;
        this.form.email = res.data[0].Email;
        this.form.phone = res.data[0].Phone;
        this.form.DOB = res.data[0].DOB;
        this.form.gender = res.data[0].Gender;
        this.form.experience = res.data[0].Experience;
        this.form.position = res.data[0].Position;
        this.form.qualification = res.data[0].Qualification;
        this.form.degree = res.data[0].Degree;
        this.form.briefProfile = res.data[0].BriefSummary;
        this.form.comment = res.data[0].Comments!='Null'?res.data[0].Comments:"";
        this.form.resumePath = res.data[0].ResumePath;
        this.form.status = res.data[0].Status;
        this.form.company = res.data[0].company;
        this.form.positionId = res.data[0].PositionId;
        	this.form.questionsforScreening=res.data[0].QuestionsforScreening	
        if (res.data[0].ResumePath) {
          this.displayFile();
        }
      });
      this.hideLoader();
    },
    // send data to server
    sendData() {
      this.$refs.validator.validate().then((result) => {
        if (result) {
          this.termsAndConditionsAcceptance = false;
          this.showMessage.isVisible = false;
          this.infoMessage.isVisible = false;
          const data = {
            id: this.form.id == 0 ? 0 : parseInt(this.form.id),
            fullName: this.form.fullName,
            email: this.form.email,
            phone: this.form.phone,
            DOB: this.form.DOB,
            gender: this.form.gender,
            experience: this.form.experience,
            position: this.form.position,
            qualification: this.form.qualification,
            degree: this.form.degree,
            briefProfile: this.form.briefProfile,
            comment: this.form.comment,
            status: this.form.status,
            resumePath: this.form.resumePath,
            company: this.form.company,
            positionId: this.form.positionId,
          };
          addUpdateApplication(data).then((res) => {
            if (res.data[0].status == 1) {
              this.form = {};
              this.showMessage.isVisible = true;
              this.showMessage.message = res.data[0].message;
            } else {
              this.infoMessage.isVisible = true;
              this.infoMessage.message = res.data[0].message;
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.error {
  color: #ff3547;
  position: absolute;
  left: 0;
  font-size: 11px;
  line-height: 13px;
  top: calc(100% + 2px);
  font-weight: 400;
}
.formDiv {
  padding-top: 10px;
}
.formLable {
  color: #515a6e;
  font-size: 15px;
}
.checkboxLable {
  color: #515a6e;
  font-size: 14px;
  margin-left: 10px;
}
.checkbox {
  position: relative;
  padding-top: 20px;
}
.label {
  display: flex;
}
.displayImageFile {
  height: 150px;
  width: 150px;
}
.displaPDF {
  height: 150px;
  width: 150px;
}
textarea{
        height: 180px !important;
    }
</style>
